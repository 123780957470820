import React from 'react'
import style from "./index.module.less"
import { XButton } from '@/components/index'
import { useIntl } from 'react-intl'
// import useGa from '@/hooks/useGa'
import comcss from "@/components/Home/index.module.less"
import { arlabBg } from '@/images/ar/index'
import useNavigate from '@/hooks/useNavigate'
import useBigScreen from "@/hooks/bigScreen"
import {useGTM} from '@/hooks/useGTM.js'

const Index = () => {
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    return <div className={style.block}>
        <h2
            className={comcss.subtitle}
        >{intl.formatMessage({ id: "ar.lab" })}</h2>
        <div
            className={comcss.subdesc}
        >{intl.formatMessage({ id: "ar.lab.des" })}</div>
        <div className={style.blockApps} style={{ backgroundImage: `url(${arlabBg})` }}>
            {/* {ifPc && <img src={arlabBg} className={style.blockAppsImg} />} */}
            <div className={style.blockAppsBtn}>
                <XButton theme="black" width="220px" onClick={() => {
                    // useGa("AR", "AR_arlab", "Click"),
                        useNavigate('/arlab')
                    useGTM('ar_click', 'Go To AR Lab', '', '')

                }}>{intl.formatMessage({ id: "nebula_goArlab" })}</XButton>
            </div>
        </div>
    </div>
}
export default Index